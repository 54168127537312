var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-app":""}},[_c('v-dialog',{attrs:{"transition":"fade-transition","fullscreen":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-container',{staticStyle:{"background-color":"rgba(255, 255, 255, 0.75)"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#a42334","size":"70","width":"10"}})],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"u-mt-md loading-text"},[_c('strong',[_vm._v("Loading ...")])])])],1)],1)],1)],1),_vm._v(" "),_c('forum-context-site-header',{attrs:{"message-search-url":_vm.headerInfo.messageSearchUrl,"site-name":_vm.headerInfo.siteName,"site-id":_vm.headerInfo.siteId,"has-site-notes":_vm.headerInfo.hasSiteNotes,"in-watchlist":_vm.siteInWatchlist,"site-url":_vm.siteUrl}}),_vm._v(" "),_c('ul',{staticClass:"site-index"},[_c('notes-legend'),_vm._v(" "),_vm._l((_vm.forumsData),function(forum,index){return _c('li',{key:index,staticClass:"site-index__row"},[_c('p',{staticClass:"site-index__name"},[(forum.site_name)?_c('a',{attrs:{"href":'/en/message-search/forum-sites/'+ forum.site_name + '/' + forum.forum}},[_vm._v("\n          "+_vm._s(forum.forum)+"\n        ")]):_c('span',[_vm._v("No Forum Name")]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":forum.forum_id,"noteName":forum.forum,"noteLinked":forum.uuid,"noteItem":'forum',"noteSiteId":forum.site_id,"noteSiteName":forum.site_name,"hasNotes":forum.has_forum_notes,"indexType":'forum',"siteLink":_vm.getForumLink(forum.site_name, forum.forum),"watchlistMessage":{
            'forum_watchlist': forum.forum_watchlist,
            'url':forum.url,
            '_source': {
              'site_id': forum.site_id,
              'site_name': forum.site_name,
              'forum': forum.forum,
            }
          }},on:{"refresh":_vm.refreshed}})],1),_vm._v(" "),_c('div',{staticClass:"site-index__info"},[_c('i',{staticClass:"u-fontsize-sm"},[_vm._v("\n          Last post in forum:\n        ")]),_vm._v(" "),_c('br'),_vm._v(" "),(forum.topic)?_c('span',[_vm._v("\n          in\n          "),_c('a',{staticClass:"link--search",attrs:{"href":'/en/message-search/?filters=site_name:'+ forum.site_name + ',topic:' + _vm.encodeURL(forum.topic_url_encoded)}},[_vm._v("\n            "+_vm._s(forum.topic)+"\n          ")]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":forum.topic_id,"noteName":forum.topic,"noteLinked":forum.uuid,"noteItem":'Topic',"noteSiteId":forum.site_id,"noteSiteName":forum.site_name,"hasNotes":forum.has_topic_notes,"indexType":'forum',"siteLink":_vm.getTopicLink(forum.site_name, forum.topic),"watchlistMessage":{
              'url':forum.url,
              'topic_watchlist': forum.topic_watchlist,
              '_source': {
                'site_id': forum.site_id,
                'site_name': forum.site_name,
                'topic': forum.topic,
                'site':forum.site
              }
            }},on:{"refresh":_vm.refreshed}})],1):(forum.topic_id)?_c('span',[_c('a',{staticClass:"link--search",attrs:{"href":'/en/message-search/?filters=site_name:'+ forum.site_name + ',topic_id:' + forum.topic_id}},[_c('i',[_vm._v("(no topic name)")])]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":forum.topic_id,"noteName":forum.topic,"noteLinked":forum.uuid,"noteItem":'topic',"noteSiteId":forum.site_id,"noteSiteName":forum.site_name,"hasNotes":forum.has_topic_notes,"indexType":'forum',"siteLink":_vm.getTopicIdLink(forum.site_name, forum.topic_id)},on:{"refresh":_vm.refreshed}})],1):_c('span',[_c('i',[_vm._v("(no topic name)")])]),_vm._v(" "),_c('br'),_vm._v("\n        by\n        "),_c('a',{staticClass:"link--search",attrs:{"href":'/en/message-search/?filters=site_name:'+ forum.site_name + ',username:' + _vm.encodeURL(forum.username_url_encoded)}},[_vm._v("\n          "+_vm._s(forum.username)+"\n        ")]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":forum.user_id,"noteName":forum.username,"noteLinked":forum.uuid,"noteItem":'user',"noteSiteId":forum.site_id,"noteSiteName":forum.site_name,"hasNotes":forum.has_user_notes,"indexType":'forum',"siteLink":_vm.getUserLink(forum.site_name, forum.username),"watchlistMessage":{
            'url':forum.url,
            'username': forum.username,
            'watchlist': forum.watchlist,
            'site': {
              'site_id': forum.site_id,
              'site_name': forum.site_name
            }
          }},on:{"refresh":_vm.refreshed}}),_vm._v(" "),_c('a',{staticClass:"u-pl-sm btn--sm",attrs:{"href":`/en/user/detail/${forum.username}/${forum.site_name}`}},[_vm._v("\n          View Username Details\n          "),_c('svg',{staticClass:"icon u-fill-current u-fontsize-sm",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"0 0 100 100"}},[_c('path',{attrs:{"d":"M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z"}})])]),_vm._v(" "),_c('br'),_vm._v("\n        "+_vm._s(_vm.formatDate(forum.posted_date))+"\n      ")],1)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }