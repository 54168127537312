<template>
  <div class="wallet-item">
    <div
      class="wallet-col"
      :title="wallet.address"
    >
      <div class="copy-btn">
        <copy-to-clipboard
          :textToClipboard="wallet.address"
          :copyClass="'address'"
        />
      </div>
    </div>
    <div class="wallet-col">
      <div v-if="hasMessages()">
        <button
          class="messages-btn u-truncate"
          :class="{'open': !hideMessages, 'closed': hideMessages}"
          :title="siteNames"
          @click="getWalletMessages()"
        >
          <span>{{ toggleMessagesText }}</span>
        </button>
        <div :class="{'collapsed': hideMessages}">
          <div
            v-if="searchState === 'loading'"
            class="alert alert--info u-text-centre"
          >
            {{ gettext('Loading ...') }}
          </div>
          <div
            v-else-if="searchState === 'retrieved'"
            class="site-list"
          >
            <div
              v-for="(message, index) in messages.data"
              :key="index"
              class="wallet-message"
            >
              <div v-if="message.title">
                <img
                  class="wallet-msg-icon"
                  src="/static/images/icons/forum_post.svg"
                  title="Forum Post"
                  alt="Forum Post"
                >
                <a
                  target="_blank"
                  :href="getWalletSiteLink(message)"
                  class="message-link"
                >
                  {{ message.site_name }} -> {{ message.title }}
                </a>
              </div>
              <div v-else>
                <img
                  class="wallet-msg-icon"
                  src="/static/images/icons/chat_message.svg"
                  title="Chat Message"
                  alt="Chat Message"
                >
                <a
                  target="_blank"
                  :href="getWalletSiteLink(message)"
                  class="message-link"
                >
                  {{ message.site_name }} -> {{ message.posted_date | formatDate }}
                </a>
              </div>
            </div>
          </div>
          <div
            v-else-if="searchState === 'error'"
            class="alert alert--error u-text-centre"
          >
            {{ gettext('Error') }}
          </div>
          <div
            v-else
            class="alert alert-info u-text-centre"
          >
            <p>No messages found.</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="wallet-col"
      :title="wallet.currency_type"
    >
      {{ wallet.currency_type }}
    </div>
    <div
      class="wallet-col"
      :title="wallet.number_of_transactions"
    >
      {{ wallet.number_of_transactions }}
    </div>
    <div
      class="wallet-col"
      :title="wallet.last_updated"
    >
      {{ wallet.last_updated }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CopyToClipboard from '../CopyToClipboard'

export default {
  name: 'WalletComponent',

  components: {
    CopyToClipboard
  },

  props: {
    wallet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hideMessages: true,
      sitesRetrieved: false,
      searchState: 'loading',
      messages: this.wallet.messages
    }
  },
  computed: {
    toggleMessagesText: function() {
      if (this.hideMessages) {
        return `${this.wallet.doc_ids.length} Messages (${this.siteNames})`
      } else {
        return this.siteNames
      }
    },
    siteNames: function() {
      return this.wallet.site_names.join(', ')
    }
  },
  methods: {
    hasMessages() {
      return this.wallet.doc_ids && this.wallet.doc_ids.length > 0
    },
    getWalletMessages() {
      this.hideMessages = !this.hideMessages

      // guard to prevent unnecessary data retrieval
      if (this.sitesRetrieved) {
        return
      }

      this.searchState = 'loading'
      let params = {
        size: 100,
        wallet_address: this.wallet.address
      }
      axios.get('/en/crypto_wallets/wallet_messages/', {params: params})
        .then(response => {
          this.messages = response.data
          this.sitesRetrieved = true
          this.searchState = 'retrieved'
        }).catch(e => {
          this.searchState = 'error'
          console.error(e)
        })
    },
    getWalletSiteLink(messageData) {
      return '/en/message-search/?doc_id=' + messageData.uuid
    },
  },
}
</script>

<style>
.collapsed {
  height: 0;
  overflow: hidden;
}
.messages-btn {
  text-align: left;
  width: 100%;
}
.messages-btn:before {
  color: #a42334;
  margin-right: 5px;
}
.messages-btn.closed:before {
  content: '▸';
}
.messages-btn.open:before {
  content: '▾';
}
.messages-btn.open {
  white-space: break-spaces;
}
.site-list {
  border: 1px solid rgba(164, 35, 52, 0.25);
  padding: 5px 5px 5px 15px;
}
.wallet-msg-icon {
  height: 25px;
  width: 25px;
}
.wallet-message {
  margin-bottom: 3px;
  white-space: normal;
}
.address button {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
}
.address button.text-copy-button::after {
  right: 0;
}

</style>
