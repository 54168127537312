<template>
  <div
    class="copy-to-clipboard-container"
    :class="copyClass"
  >
    <button
      @click="copyToClipboard($event, textToClipboard)"
      class="text-copy-button"
      type="button"
      :title="`Copy: ` + textToClipboard"
    >
      {{ textToClipboard }}
    </button>
    <div
      v-if="copySuccess"
      ref="copySuccessMsg"
      class="copy-success"
      :style="{ top: copySuccessY + 'px', left: copySuccessX + 'px' }"
    >
      <p>Copied to clipboard!</p>
    </div>
  </div>
</template>



<script>
export default {
  name: 'CopyToClipboard',

  props: {
    textToClipboard: {
      type: String,
      required: true
    },
    copyClass: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      copySuccess: false,
      copySuccessX: null,
      copySuccessY: null
    }
  },

  methods: {
    copyToClipboard(e, value) {
      this.copySuccess = true
      this.copySuccessX = e.clientX + 20
      this.copySuccessY = e.clientY + 20
      navigator.clipboard.writeText(value)
      this.$nextTick(() => {
        this.$refs.copySuccessMsg.addEventListener('animationend', () => {
          this.copySuccess = false
        })
      })
    }
  },
}
</script>



<style scoped>
.copy-to-clipboard-container {
  position: relative;
}
.copy-success {
  position: fixed;
  background: #e2ce66;
  border: 1px solid #000;
  padding: 10px 20px;
  animation-name: fadeOutSuccess;
  animation-duration: 1s;
  animation-delay: 3s;
}
.copy-success p {
  margin-bottom: 0;
}
.text-copy-button {
  color: #a42334;
  text-decoration: underline;
}
.text-copy-button:after {
  background-image: url('/images/icons/content_copy48.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  right: -25px;
}
</style>

