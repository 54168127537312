import { render, staticRenderFns } from "./MessageResultChat.vue?vue&type=template&id=22bb8df6&scoped=true&"
import script from "./MessageResultChat.vue?vue&type=script&lang=js&"
export * from "./MessageResultChat.vue?vue&type=script&lang=js&"
import style0 from "./MessageResultChat.vue?vue&type=style&index=0&id=22bb8df6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22bb8df6",
  null
  
)

export default component.exports