// import '@mdi/font/css/materialdesignicons.min.css'
// import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
// import './../static/bundle.css'
import 'babel-polyfill'
import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import vuetify from './plugins/vuetify' // path to vuetify export

import Clipboard from 'clipboard'

import FileUploadForm from './FileUploadForm'
import ContactForm from './ContactForm'
import AskQuestionForm from './account/AskQuestionForm'
import RequestDemoForm from './account/RequestDemoForm'
import SubmitFeedbackForm from './account/SubmitFeedbackForm'
import DemoList from './DemoList'
import ImageSearchForm from './ImageSearchForm'
import ImageSearch from './ImageSearch'
import ImageSearchResultArea from './image_search/ResultArea'
import ImageSearchFilterResults from './image_search/FilterResults'
import VideoUpload from './VideoUpload'
import VideoUploadForm from './VideoUploadForm'
import TopicList from './TopicList.vue'
import AllNotes from './notes/AllNotes.vue'
import UserNote from './notes/UserNote.vue'
import CryptoWallets from './crypto_wallets/CryptoWallets.vue'

import store from './message_search/store'
import MessageSearch from './message_search/MessageSearch'
import WatchList from './watchlist/WatchList'
import ChatContextView from './chat_context/ChatContextView'
import ChatContextSiteHeader from './chat_context/ChatContextSiteHeader'
import ChatFocusView from './chat_context/ChatFocusView'
import SiteIndex from './SiteIndex'
import ForumIndex from './ForumIndex'
import TopicsIndex from './TopicsIndex'
import ForumContextSiteHeader from './forum_search/ForumContextSiteHeader'
import TopicContextSiteHeader from './forum_search/TopicContextSiteHeader'
import './filters'
import UserWatchlistIndex from './watchlist/UserWatchlistIndex.vue'
import SightingsTablePopup from './image_search/SightingsTablePopup.vue'
import UserDetail from './account/UserDetail.vue'

import StarterPage from './StarterPage'

Vue.component(FileUploadForm.name, FileUploadForm)
Vue.component(ContactForm.name, ContactForm)
Vue.component(AskQuestionForm.name, AskQuestionForm)
Vue.component(RequestDemoForm.name, RequestDemoForm)
Vue.component(SubmitFeedbackForm.name, SubmitFeedbackForm)
Vue.component(DemoList.name, DemoList)
Vue.component(ImageSearchForm.name, ImageSearchForm)
Vue.component(ImageSearch.name, ImageSearch)
Vue.component(ImageSearchResultArea.name, ImageSearchResultArea)
Vue.component(ImageSearchFilterResults.name, ImageSearchFilterResults)
Vue.component(SightingsTablePopup.name, SightingsTablePopup)
Vue.component(VideoUpload.name, VideoUpload)
Vue.component(VideoUploadForm.name, VideoUploadForm)
Vue.component(AllNotes.name, AllNotes)
Vue.component(UserNote.name, UserNote)
Vue.component(CryptoWallets.name, CryptoWallets)

Vue.component(MessageSearch.name, MessageSearch)
Vue.component(TopicList.name, TopicList)
Vue.component(WatchList.name, WatchList)
Vue.component(ChatContextView.name, ChatContextView)
Vue.component(ChatContextSiteHeader.name, ChatContextSiteHeader)
Vue.component(ChatFocusView.name, ChatFocusView)
Vue.component(ForumContextSiteHeader.name, ForumContextSiteHeader)
Vue.component(TopicContextSiteHeader.name, TopicContextSiteHeader)
Vue.component(SiteIndex.name, SiteIndex)
Vue.component(ForumIndex.name, ForumIndex)
Vue.component(TopicsIndex.name, TopicsIndex)
Vue.component(UserWatchlistIndex.name, UserWatchlistIndex)
Vue.component(UserDetail.name, UserDetail)

Vue.component(StarterPage.name, StarterPage)

if (window.sentry_dsn) {
  Raven.config(window.sentry_dsn, {
    release: window.sentry_release
  }).addPlugin(RavenVue, Vue).install()
} else {
  console.error('window.sentry_dsn not defined - Sentry not enabled')
}

if (!window.django) {
  console.error("window.django not defined - ensure Django's jsi18n is loaded before this script")
}

Vue.prototype.gettext = window.django.gettext
Vue.prototype.pgettext = window.django.pgettext
Vue.prototype.lang = window.django.LANGUAGE_CODE

const vueApps = document.getElementsByClassName('vue-app')

for (let i = 0; i < vueApps.length; i++) {
  const element = vueApps[i]
  const newVue = new Vue({
    vuetify,
    store: store,
    el: element,
    data: {
      document: document,
      window: window
    }})
}

window.Vue = Vue


const responsiveCellHeaders = function(tableEl) {
  // console.log('table el:', tableEl)
  try {
    const thArray = []
    const table = document.getElementById(tableEl)
    const tableHeaders = table.getElementsByTagName('th')
    for (let i = 0; i < tableHeaders.length; i++) {
      const headingText = tableHeaders[i].innerHTML
      thArray.push(headingText)
    }
    const styleEl = document.createElement('style')
    document.head.appendChild(styleEl)
    const styleSheet = styleEl.sheet
    for (let i = 0; i < thArray.length; i++) {
      styleSheet.insertRule(
        `#${tableEl} td:nth-child(${i + 1})::before { content:'${thArray[i]}${thArray[i].length ? ':' : ''}' }`,
        styleSheet.cssRules.length
      )
    }
  } catch (e) {
    console.log('responsiveCellHeaders error:', e)
  }
}
const responsiveTables = document.getElementsByClassName('responsive-table')
for (let i = 0; i < responsiveTables.length; i++) {
  const tableId = responsiveTables[i].id
  responsiveCellHeaders(tableId)
}


const filterSetToggle = function(e) {
  if (!e.target.classList.contains('filter__select')) return

  const selectAll = e.target.classList.contains('select-all') ? true : false
  selectAll
    ? (e.target.classList.remove('select-all'), e.target.innerHTML = 'Select none')
    : (e.target.classList.add('select-all'), e.target.innerHTML = 'Select all')

  const filterParent = e.target.closest('.filter')
  const filterSet = filterParent.querySelectorAll('input[type="checkbox"]')
  filterSet.forEach(function(el) {
    el.checked = selectAll
  })
}
document.addEventListener('click', filterSetToggle)

window.setupCopy = function() {
  let clip = new Clipboard('[data-clipboard-text]')

  clip.on('success', function(e) {
    let oldClass = e.trigger.className
    if (oldClass.indexOf('u-copied') === -1) {
      e.trigger.className += ' u-copied'
      window.setTimeout(function() { e.trigger.className = oldClass }, 1000)
    }
  })
}


const detailsGroupToggles = document.querySelectorAll('[data-details-target]')

document.addEventListener('click', function(e) {
  if (!e.target.getAttribute('data-details-target')) return

  let btnText = e.target.querySelector('span')
  let isGroupOpen = e.target.getAttribute('data-details-group-open') === 'true'
  let detailsGroupId = e.target.getAttribute('data-details-target')
  let detailsGroup = [...document.querySelectorAll(`[data-details-group="${detailsGroupId}"]`)]

  detailsGroup.forEach(details => {
    details.open = !isGroupOpen
  })
  btnText.innerText = isGroupOpen ? gettext('Show') : gettext('Hide')
  e.target.setAttribute('data-details-group-open', !isGroupOpen)
})

