<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <topic-context-site-header
      :message-search-url="headerInfo.messageSearchUrl"
      :site-name="headerInfo.siteName"
      :site-id="headerInfo.siteId"
      :forum-id="headerInfo.forumId"
      :forum="decodeURIComponent(headerInfo.forum)"
      :has-forum-notes="headerInfo.hasForumNotes"
      :in-watchlist="topicInWatchlist"
      :site-url="siteUrl"
    />
    <ul
      v-if="topicsData.length > 0"
      class="site-index"
    >
      <notes-legend />
      <li
        v-for="(topic, index) in topicsData"
        :key="index"
        class="site-index__row"
      >
        <p class="site-index__name">
          <a
            v-if="!topic.topic || topic.topic_id"
            :href="topicLink(topic)"
            class="link--search"
          >
            <span v-if="topic.topic">{{ topic.topic }}</span>
            <i v-else>(no topic name)</i>
          </a>
          <i v-else>(no topic name)</i>
          <note-buttons
            :noteId="topic.topic_id"
            :noteName="topic.topic"
            :noteLinked="topic.uuid"
            :noteSiteId="topic.site_id"
            :noteSiteName="topic.site_name"
            :noteItem="'Topic'"
            :hasNotes="topic.has_topic_notes"
            :indexType="'forum'"
            :siteLink="getTopicLink(topic.site_name, topic.topic, topic.topic_id)"
            :watchlistMessage="{
              'topic_watchlist': topic.topic_watchlist,
              'url':topic.url,
              '_source': {
                'site_id': topic.site_id,
                'site_name': topic.site_name,
                'topic': topic.topic,
                'site':topic.site
              }
            }"
            @refresh="refreshed"
          />
        </p>
        <div class="site-index__info">
          <i class="u-fontsize-sm">
            Last post in topic:
          </i>
          <br>
          <span v-if="topic.username">
            by
            <a
              :href="'/en/message-search/?filters=site_name:' + topic.site_name + ',username:' + topic.username"
              class="link--search"
            >
              {{ topic.username }}
            </a>
            <note-buttons
              :noteId="topic.user_id"
              :noteName="topic.username"
              :noteLinked="topic.uuid"
              :noteSiteId="topic.site_id"
              :noteSiteName="topic.site_name"
              :noteItem="'user'"
              :hasNotes="topic.has_user_notes"
              :indexType="'forum'"
              :siteLink="getUserLink(topic.site_name, topic.username)"
              :watchlistMessage="{
                'username': topic.username,
                'watchlist': topic.watchlist,
                'url':topic.url,
                'site': {
                  'site_id': topic.site_id,
                  'site_name': topic.site_name,
                  'site': topic.site
                }
              }"
              @refresh="refreshed"
            />
          </span>
          <a
            class="u-pl-sm btn--sm"
            :href="`/en/user/detail/${topic.username}/${topic.site_name}`"
          >
            View Username Details
            <svg
              class="icon u-fill-current u-fontsize-sm"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 100 100"
            >
              <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z" />
            </svg>
          </a>
          <br>
          {{ formatDate(topic.posted_date) }}
        </div>
      </li>
    </ul>
    <p
      v-if="!loading && topicsData.length == 0"
      class="alert alert--error"
    >
      No topics found
    </p>
  </div>
</template>

<script>
import NoteButtons from './notes/NoteButtons'
import NotesLegend from './notes/NotesLegend'
import TopicContextSiteHeader from './forum_search/TopicContextSiteHeader.vue'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'TopicsIndex',
  components: {
    TopicContextSiteHeader,
    NoteButtons,
    NotesLegend
  },
  props: {
    siteName: {
      type: String,
      required: true
    },
    forum: {
      type: String,
      required: true
    },
    headerInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      topicsData: [],
      loading: false,
      topicInWatchlist: false,
      siteUrl:'',

    }
  },
  mounted() {
    const csrftoken = Cookies.get('csrftoken')
    const url = '/en/indices/' + this.siteName + '/' + this.forum + '/topic_index/'
    this.loading = true
    axios.get(url, {'headers': {'X-CSRFToken': csrftoken}})
      .then(response => {
        this.topicsData = response.data
        this.loading = false
        this.siteUrl=this.topicsData[0].url
        this.topicInWatchlist = this.topicsData[0].forum_watchlist
      })
      .catch(error => {
        console.log(error)
        this.topicsData = []
        this.loading = false
      })
  },
  methods: {
    getTopicLink(siteName, topic, topicId) {
      let getSiteName = (siteName) ? 'site_name:' + siteName : ''
      let getTopicName = (topic) ? ',topic:' + this.encodeURL(topic) : ''
      let getTopicId = (topicId) ? ',topic_id:' + topicId : ''
      return new URL('/en/message-search/?filters=' + getSiteName + getTopicName + getTopicId, document.baseURI).href
    },
    getUserLink(siteName, userName) {
      return new URL('/en/message-search/?filters=site_name:' + siteName + ',username:' + userName, document.baseURI).href
    },
    encodeURL(topic) {
      return encodeURIComponent(topic)
    },
    topicLink(topic) {
      let siteName = (topic.site_name) ? 'site_name:' + topic.site_name : ''
      let topicName = (topic.topic) ? ',topic:' + this.encodeURL(topic.topic) : ''
      let topicId = (topic.topic_id) ? ',topic_id:' + topic.topic_id : ''
      return '/en/message-search/?filters=' + siteName + topicName + topicId
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      return new Date(date * 1000).toLocaleString('en-US', options)
    },
    refreshed() {
      const csrftoken = Cookies.get('csrftoken')
      let siteDataObj = {
        'site_name': this.topicsData[0].site_name,
        'forum': this.topicsData[0].forum
      }
      axios.get('/en/message-search/refresh_topic_index/', {params: siteDataObj}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.topicsData = response.data.data
      })
    }
  }
}
</script>

<style scoped>
.v-progress-circular,
.loading-text {
  margin: auto;
}
</style>
