<template>
  <div>
    <!-- STANDARD TABLE -->
    <h2>
      Standard table
    </h2>
    <div class="table">
      <div class="table-header">
        <div>Name</div>
        <div>Position</div>
        <div>Start Date</div>
        <div>Status</div>
        <div>Verified</div>
      </div>
      <div
        v-for="(item, index) in team"
        :key="index"
        class="table-item"
      >
        <div>{{ item.name }}</div>
        <div>{{ item.position }}</div>
        <div>{{ item.startDate }}</div>
        <div>{{ item.status }}</div>
        <div>
          <img
            v-if="item.verified"
            src="/images/icons/topic-list-scraped-true.svg"
          >
          <img
            v-if="!item.verified"
            src="/images/icons/topic-list-scraped-false.svg"
          >
        </div>
      </div>
    </div>
    <!-- STANDARD TABLE END -->

    <!-- ENHANCED TABLE -->
    <h2>
      Enhanced Table
    </h2>
    <div>
      <div class="enhanced-table-filters">
        <div class="enhanced-table-search">
          <input
            v-model="searchInput"
            type="text"
            placeholder="Search"
            @keyup="searchTable"
            @keyup.delete="searchTable"
          >
          <button
            v-if="searchInput"
            class="enhanced-table-clear-search"
            @click="clearSearch"
          >
            Clear Search
          </button>
        </div>
        <div class="enhanced-table-filter">
          <label>
            Filter by Site:
          </label>
          <select
            v-model="tableFilter"
            :class="{ 'active': tableFilter }"
            @change="handleFilterChange"
          >
            <option
              v-for="site in userSites"
              :key="site"
            >
              {{ site }}
            </option>
          </select>
          <button
            v-if="tableFilter"
            class="clear-enhanced-table-filter"
            @click="clearTableFilter"
          >
            &#x2716;
          </button>
        </div>
      </div>
      <div class="enhanced-table">
        <div class="enhanced-table-header">
          <div>
            <a
              href="#"
              :class="{'asc': siteSortDir == 'asc', 'desc': siteSortDir == 'desc'}"
              @click.prevent="sortSite"
            >
              Site
            </a>
          </div>
          <div>
            <a
              href="#"
              :class="{'asc': userSortDir == 'asc', 'desc': userSortDir == 'desc'}"
              @click.prevent="sortUser"
            >
              User
            </a>
          </div>
          <div>
            <a
              href="#"
              :class="{'asc': dateSortDir == 'asc', 'desc': dateSortDir == 'desc'}"
              @click.prevent="sortDate"
            >
              First Post
            </a>
          </div>
          <div>Latest Post</div>
          <div>
            <a
              href="#"
              :class="{'asc': activeSortDir == 'asc', 'desc': activeSortDir == 'desc'}"
              @click.prevent="sortActive"
            >
              Active
            </a>
          </div>
        </div>
        <div
          v-for="(item, index) in displayedUsers"
          :key="index"
          class="enhanced-table-item"
        >
          <div>{{ item.site }}</div>
          <div>{{ item.user }}</div>
          <div>
            {{ displayDate(item.firstPost) }}
          </div>
          <div>
            {{ displayDate(item.latestPost) }}
          </div>
          <div>
            <img
              v-if="item.active"
              src="/images/icons/enhanced-table-active.svg"
            >
            <img
              v-if="!item.active"
              src="/images/icons/enhanced-table-inactive.svg"
            >
          </div>
        </div>
      </div>
      <div
        v-if="noResults"
        class="search-message"
      >
        No Results
      </div>
    </div>
    <!-- ENHANCED TABLE END -->

    <!-- POPUP -->
    <h2>Popup with button</h2>
    <div>
      <div class="popup-section-container">
        <button
          class="popup-btn"
          @click="showPopup = true"
        >
          Show Popup
        </button>
        <div
          v-if="showPopup"
          class="popup-container"
          @click.self="showPopup = false"
        >
          <div class="popup">
            <button
              class="popup-close"
              @click="showPopup = false"
            >
              &#x2716;
            </button>
            <h3>Popup</h3>
            <textarea />
            <p>
              <button class="popup-submit">
                Submit
              </button>
            </p>
            <p>Other popup content</p>
            <p>Other popup content</p>
          </div>
        </div>
      </div>
    </div>
    <!-- POPUP END -->

    <!-- PAGINATION -->
    <h2>Pagination</h2>
    <div class="pagination-table">
      <div class="pagination-table-header">
        <div>Site</div>
        <div>Sha1</div>
        <div>Verified</div>
        <div>Active</div>
      </div>
      <div
        v-for="(item, index) in paginationData"
        :key="index"
        class="pagination-table-item"
      >
        <div>
          {{ item.name }}
        </div>
        <div>
          {{ item.sha1 }}
        </div>
        <div>
          {{ item.verified }}
        </div>
        <div>
          {{ item.active }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="pagination-buttons">
        <button @click="paginationFirstPage">
          |&lt;
        </button>
        <button @click="paginationPrev">
          &lt;
        </button>
        <input
          v-model="paginationCurrentPage"
          type="text"
          @change="paginationPageSelect"
        > / {{ paginationPages }}
        <button @click="paginationNext">
          &gt;
        </button>
        <button @click="paginationLastPage">
          &gt;|
        </button>
      </div>
      <select
        v-model="paginationTotal"
        @change="paginationSelectDisplay"
      >
        <option>10</option>
        <option>25</option>
        <option>50</option>
      </select>
    </div>
    <!-- PAGINATION END -->

    <!-- API -->
    <div>
      <h2>API</h2>
      <div class="api-buttons">
        <div>
          <button
            class="load-json-button"
            @click="loadJson"
          >
            Load json
          </button>
        </div>
        <div class="switch-container">
          Error
          <input
            id="toggle-switch"
            v-model="apiEnabled"
            type="checkbox"
            name="toggle-switch"
          >
          <label for="toggle-switch" />
          Success
        </div>
        <div>
          <button
            class="clear-api-button"
            @click="clearAPITable"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="api-table-container">
        <div class="spinner-container">
          <div
            v-if="!loaded && loading"
            class="loading-spinner"
          />
        </div>
        <div
          v-if="showErrorMessage"
          class="error-message"
        >
          <p>{{ errorMessage }}</p>
          <p>
            <button @click="showErrorMessage = false">
              Close
            </button>
          </p>
        </div>
        <div
          v-if="loaded"
          class="api-table"
        >
          <div class="api-table-header">
            <div>Site</div>
            <div>Sha1</div>
            <div>Verified</div>
            <div>Active</div>
          </div>
          <div
            v-for="(item, index) in jsonApi"
            :key="index"
            class="api-table-item"
          >
            <div>
              {{ item.name }}
            </div>
            <div>
              {{ item.sha1 }}
            </div>
            <div>
              {{ item.verified }}
            </div>
            <div>
              {{ item.active }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- API END -->

    <!-- SELECT -->
    <div>
      <h2>Select</h2>
      <div style="display: flex;">
        <div>
          <select
            v-model="selectSelected"
          >
            <option value="">
              Select an option
            </option>
            <option
              v-for="(option, index) in selectOptions"
              :key="index"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div style="padding-left: 30px;">
          <p v-if="selectSelected">
            Selected value: <strong>{{ selectSelected }}</strong>
          </p>
        </div>
      </div>
    </div>
    <!-- SELECT END -->
    <h2>Icons</h2>
  </div>
</template>

<script>
import starterPagination from './starter-pagination.json'
export default {
  name: 'StarterPage',
  data() {
    return {
      team: [
        { 'name': 'Bill', 'position': 'Boss', 'startDate': 'May 1', 'status': 'Active', 'verified': true },
        { 'name': 'Joe', 'position': 'Manager', 'startDate': 'Jan 30', 'status': 'Active', 'verified': false },
        { 'name': 'Joan', 'position': 'Goon', 'startDate': 'Dec 17', 'status': 'Not Active', 'verified': false },
        { 'name': 'Lester', 'position': 'Lackey', 'startDate': 'Oct 2', 'status': 'Active', 'verified': true }
      ],
      users: [
        { 'site': 'north', 'user': 'CrunkX', 'firstPost': '07/04/2014 10:51:52', 'latestPost': '13/07/2024 10:51:52', 'active': true },
        { 'site': 'east', 'user': 'VerticalPound', 'firstPost': '14/12/2019 10:51:52', 'latestPost': '08/08/2023 10:51:52', 'active': true },
        { 'site': 'east', 'user': 'FrozenPizza', 'firstPost': '30/01/2002 10:51:52', 'latestPost': '27/08/2024 10:51:52', 'active': true },
        { 'site': 'north', 'user': 'MerciMer', 'firstPost': '21/05/2023 10:51:52', 'latestPost': '23/08/2024 10:51:52', 'active': false },
        { 'site': 'south', 'user': 'DefendMars', 'firstPost': '27/08/2024 10:51:52', 'latestPost': '15/06/2024 10:51:52', 'active': true },
        { 'site': 'west', 'user': 'Toxic Moon', 'firstPost': '11/03/1999 10:51:52', 'latestPost': '01/02/2023 10:51:52', 'active': true },
        { 'site': 'south', 'user': 'MaskorNo', 'firstPost': '02/08/2015 10:51:52', 'latestPost': '16/11/2017 10:51:52', 'active': false },
        { 'site': 'south', 'user': 'Blevim', 'firstPost': '09/09/2009 10:51:52', 'latestPost': '29/04/2024 10:51:52', 'active': true }
      ],
      userSites: [],
      displayedUsers: null,
      tableFilter: null,
      showPopup: false,
      searchInput: '',
      sortDir: {'desc': 1, 'asc': -1},
      siteSortDir: null,
      userSortDir: null,
      activeSortDir: null,
      dateSortDir: null,
      fullPaginationData: starterPagination,
      paginationData: [],
      paginationTotal: 10,
      paginationStart: 0,
      paginationPages: null,
      paginationCurrentPage: 1,
      paginationPrevCurPage: null,
      noResults: false,
      jsonApi: {},
      loaded: false,
      loading: false,
      apiEnabled: true,
      errorMessage: '',
      showErrorMessage: false,
      selectSelected: '',
      selectOptions: ['First', 'Second', 'Third', 'Fourth', 'Fifth']
    }
  },
  mounted() {
    this.displayedUsers = this.users
    this.userFilters()
    this.pagination()
  },
  methods: {
    userFilters() {
      let usersArray = []
      this.users.forEach(user => {
        usersArray.push(user.site)
      })
      this.userSites = usersArray.filter((v, i, a) => a.indexOf(v) === i)
    },
    handleFilterChange() {
      this.displayedUsers = this.users
      if (this.tableFilter) {
        this.displayedUsers = this.displayedUsers.filter(user => user.site === this.tableFilter)
      }
    },
    clearTableFilter() {
      this.tableFilter = null
      if (this.searchInput !== '') {
        this.displayedUsers = this.users
        this.searchTable()
      } else this.displayedUsers = this.users
    },
    searchTable() {
      let searchResult = []
      this.noResults = false
      searchResult = this.displayedUsers.filter(user => {
        return user.user.toUpperCase().includes(this.searchInput.toUpperCase()) || user.site.toUpperCase().includes(this.searchInput.toUpperCase())
      })
      if (this.searchInput !== '') {
        if (searchResult.length > 0) {
          this.displayedUsers = searchResult
        } else {
          this.noResults = true
          this.displayedUsers = []
        }
      } else if (this.searchInput === '') this.displayedUsers = this.users
    },
    clearSearch() {
      this.searchInput = ''
      this.noResults = false
      if (this.tableFilter) this.handleFilterChange()
      else this.displayedUsers = this.users
    },
    convertDate(convertDate) {
      let modifiedDate = convertDate.split('/')
      let modifiedDateTime = modifiedDate[2].split(' ')
      let newDate = modifiedDateTime[0] + '-' + modifiedDate[1] + '-' + modifiedDate[0] + 'T' + modifiedDateTime[1] + 'Z'
      let date = new Date(newDate)
      return date
    },
    displayDate(convertDate) {
      let date = this.convertDate(convertDate)
      return date.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})
    },
    sortSite() {
      this.sortReset('site')
      this.siteSortDir = (this.siteSortDir === 'desc') ? 'asc' : 'desc'
      this.displayedUsers.sort((a, b) => this.sortDir[this.siteSortDir] * (b.site.localeCompare(a.site)))
    },
    sortUser() {
      this.sortReset('user')
      this.userSortDir = (this.userSortDir === 'desc') ? 'asc' : 'desc'
      this.displayedUsers.sort((a, b) => this.sortDir[this.userSortDir] * (b.user.localeCompare(a.user)))
    },
    sortActive() {
      this.sortReset('active')
      this.activeSortDir = (this.activeSortDir === 'desc') ? 'asc' : 'desc'
      this.displayedUsers.sort((a, b) => this.sortDir[this.activeSortDir] * (b.active - a.active))
    },
    sortDate() {
      this.sortReset('date')
      this.dateSortDir = (this.dateSortDir === 'desc') ? 'asc' : 'desc'
      this.displayedUsers.sort((a, b) => {
        let dateA = this.convertDate(a.firstPost)
        let dateB = this.convertDate(b.firstPost)
        if (this.dateSortDir === 'desc') return dateB - dateA
        else if (this.dateSortDir === 'asc') return dateA - dateB
      })
    },
    sortReset(sort) {
      if (sort !== 'site') this.siteSortDir = null
      if (sort !== 'user') this.userSortDir = null
      if (sort !== 'date') this.dateSortDir = null
      if (sort !== 'active') this.activeSortDir = null
    },
    pagination() {
      this.paginationPages = this.fullPaginationData.length / this.paginationTotal
      this.paginationData = this.fullPaginationData.slice(this.paginationStart, parseInt(this.paginationTotal) + this.paginationStart)
      this.paginationPrevCurPage = this.paginationCurrentPage
    },
    paginationNext() {
      if (this.paginationStart + parseInt(this.paginationTotal) < this.fullPaginationData.length) this.paginationStart += parseInt(this.paginationTotal)
      if (this.paginationCurrentPage < this.paginationPages) this.paginationCurrentPage++
      this.pagination()
    },
    paginationPrev() {
      if (this.paginationStart > 0) this.paginationStart -= parseInt(this.paginationTotal)
      if (this.paginationCurrentPage > 1) this.paginationCurrentPage--
      this.pagination()
    },
    paginationFirstPage() {
      this.paginationStart = 0
      this.paginationCurrentPage = 1
      this.pagination()
    },
    paginationLastPage() {
      this.paginationStart = this.fullPaginationData.length - this.paginationTotal
      this.paginationCurrentPage = this.fullPaginationData.length / this.paginationTotal
      this.pagination()
    },
    paginationPageSelect() {
      if (this.paginationCurrentPage > this.paginationPages) {
        this.paginationCurrentPage = this.paginationPrevCurPage
      } else {
        this.paginationStart = (parseInt(this.paginationCurrentPage) - 1) * this.paginationTotal
        this.pagination()
      }
    },
    paginationSelectDisplay() {
      this.paginationCurrentPage = 1
      this.paginationStart = 0
      this.pagination()
    },
    loadJson() {
      this.loaded = false
      this.loading = true
      this.showErrorMessage = false
      this.jsonApi = {}
      if (this.apiEnabled) {
        setTimeout(() => {
          this.apiTable()
        }, 2000)
      } else {
        setTimeout(() => {
          this.loading = false
          this.errorMessage = 'Something went wrong'
          this.showErrorMessage = true
        }, 2000)
      }
    },
    apiTable() {
      fetch('/starter-page-api/', {method: 'get'})
        .then(response => { return response.json() })
        .then(data => {
          this.jsonApi = data
          this.loading = false
          this.loaded = true
        })
    },
    clearAPITable() {
      this.jsonApi = {}
      this.loaded = false
    }
  }
}
</script>

<style scoped>
h2 {
  margin-top: 50px;
}
/* Standard Table */
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.table > div {
  display: table-row;
}
.table > div div {
  display: table-cell;
  padding: 10px;
}
.table > div div:last-child {
  width: 100px;
  text-align: center;
}
.table-header {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.table-item:nth-child(odd) {
  background-color: #eee;
}
.table-item img {
  height: 30px;
}
/* Enhanced Table */
.enhanced-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.enhanced-table > div {
  display: table-row;
}
.enhanced-table > div div {
  display: table-cell;
  padding: 10px;
}
.enhanced-table > div div:last-child {
  text-align: center
}
.enhanced-table-header {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.enhanced-table-header a {
  text-decoration: none;
}
.enhanced-table-item:nth-child(odd) {
  background-color: #eee;
}
.enhanced-table-filters {
  position: relative;
}
.enhanced-table-search {
  width: 500px;
}
.enhanced-table-search input {
  width: 326px;
}
.enhanced-table-clear-search {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 6px;
}
.enhanced-table-filters {
  margin-bottom: 30px;
}
.enhanced-table-filter {
  position: absolute;
  top: 0;
  right: 0;
  width: 330px;
}
.enhanced-table-filter label {
  width: auto;
  margin-right: 10px;
}
.enhanced-table-filter select {
  width: 200px;
}
.enhanced-table-filter select.active {
  background: none;
}
.clear-enhanced-table-filter {
  color: #fff;
  background: #c22c3f;
  position: absolute;
  top: 13px;
  right: 10px;
  border-radius: 6px;
  padding: 2px;
  line-height: 18px;
}
.desc:after {
  content: '▼';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.asc:after {
  content: '▲';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.search-message {
  text-align: center;
  font-weight: bold;
  padding: 20px;
}
/* Popup */
.popup-section-container {
  margin-top: 20px;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
}
.popup {
  position: fixed;
  top: 200px;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
  width: 60vw;
  background: #f5f5f4;
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
  box-sizing: border-box;
}
.popup textarea {
  height: 80px;
  resize: none;
}
.popup-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.popup-submit {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 10px;
}
.popup-submit:active {
  background: #a42334;
}
.popup-close {
  position: absolute;
  top: 5px;
  right: 10px;
}
/* Pagination */
.pagination-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.pagination-table > div {
  display: table-row;
}
.pagination-table > div div {
  display: table-cell;
  padding: 10px;
}
.pagination-table-header {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.pagination {
  position: relative;
  padding: 10px 0;
  margin: 10px 0;
  border: 1px solid #ccc;
}
.pagination-buttons {
  margin-left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.pagination button {
  border-radius: 5px;
  width: 50px;
  padding: 5px 0;
  background: #c22c3f;
  color: #fff;
  font-weight: bold;
}
.pagination input[type="text"] {
  min-width: 50px;
  width: 50px;
  text-align: center;
  border-radius: 5px;
  padding: 3px 5px;
}
.pagination select {
  min-width: 75px;
  width: 75px;
  position: absolute;
  right: 5px;
  border-radius: 5px;
  padding: 3px 5px;
}
/* API */
.load-json-button {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.api-table-container {
  min-height: 400px;
  position: relative;
}
.api-buttons {
  display:flex;
  align-items: center;
}
.spinner-container {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-spinner {
  color: #c22c3f;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}
.switch-container {
  padding: 0 30px;
  font-size: 14px;
}
#toggle-switch {
  width: 0;
  height: 0;
  display: none;
  position: static;
}
label[for="toggle-switch"] {
  display: inline-block;
  width: 50px;
  height: 30px;
  background-color: #ccc;
  border: 2px solid #ccc;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  vertical-align: middle;
}
label[for="toggle-switch"]:before {
  display: none;
}
label[for="toggle-switch"]:after {
  content: '';
  width: 24px;
  height: 24px;
  background-color: #fff;
  position: absolute;
  border-radius: 70px;
  top: 1px;
  left: 1px;
  transition: 0.5s;
  opacity: 1;
}
input[name="toggle-switch"]:checked + label[for="toggle-switch"] {
  background-color: #c22c3f;
  border: 2px solid #c22c3f;
}
input[name="toggle-switch"]:checked + label[for="toggle-switch"]:after {
  left: calc(100% - 1px);;
  transform: translateX(-100%);
  top: 1px;
  width: 24px;
  height: 24px;
  border: 0;
}
.api-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.api-table > div {
  display: table-row;
}
.api-table > div div {
  display: table-cell;
  padding: 10px;
}
.api-table-header {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid #c22c3f;
  padding: 20px;
  border-radius: 5px;
}
.error-message p:last-child {
  margin-bottom: 0;
}
.error-message button {
  margin: auto;
  display: block;
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.clear-api-button {
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid #c22c3f;
  color: #c22c3f;
}
@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em,
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
     -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
     -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
     -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
     -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
