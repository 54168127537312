<template>
  <div
    :class="`message message--chat ${rootclass}`"
  >
    <div class="message__header u-pr-lg">
      <p class="u-m-0">
        {{ pgettext('noting author of message', 'by') }}
        <a
          v-if="message._source.username"
          class="link--search"
          :href="`${messageSearchUrl}?filters=site_name:${siteName},username:${message._source.username}`"
        >
          {{ message._source.username }}</a>
        <i v-else>{{ pgettext('(unknown)') }}</i>
        <note-buttons
          :noteId="message._source.user_id"
          :noteName="message._source.username"
          :noteLinked="message._id"
          :noteSiteId="message._source.site_id"
          :noteSiteName="message._source.site_name"
          :noteItem="'chat user'"
          :hasNotes="message._source.has_chat_user_notes"
          :indexType="'chat'"
          :siteLink="getUserLink(message._source.site_name, message._source.username)"
          :watchlistMessage="message"
          @refresh="refresh"
        />
        {{ pgettext('noting date of message', 'on') }}
        <time datetime="">
          {{ readableDate(message._source.posted_date) }}
        </time>
        <a
            class="u-pl-sm btn--sm"
            :href="`/en/user/detail/${message._source.username}/${message._source.site_name}`"
          >
            View Username Details
            <svg
              class="icon u-fill-current u-fontsize-sm"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 100 100"
            >
              <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z" />
            </svg>
          </a>
      </p>
      <div
        v-if="!featured"
        class="timeline__target"
      >
        <!-- [[ url('chat_message_feature', kwargs={'site_name': site_name, 'doc_id': doc_id}) ]][% if timeframe %]?timeframe=[[timeframe]][% endif %] -->
        <a
          :href="`${messageSearchUrl}chat-sites/${siteName}/${message._id}/${targeted_link}`"
          title="Focus on this message"
          class="u-p-xs"
          @click.prevent="$emit('target', {messageId: message._id, username: message._source.username})"
        >
          <svg
            class="timeline__target-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            focusable="false"
            aria-hidden="true"
            width="20"
          >
            <path d="M70 45h28v10H70zM2 45h28v10H2zM45 70h10v28H45zM45 2h10v28H45z" />
            <path
              d="M50 32.5c9.6 0 17.5 7.9 17.5 17.5S59.6 67.5 50 67.5 32.5 59.6 32.5 50 40.4 32.5 50 32.5m0-10c-15.2 0-27.5 12.3-27.5 27.5S34.8 77.5 50 77.5 77.5 65.2 77.5 50 65.2 22.5 50 22.5z"
            />
          </svg>
          <span class="u-vh">Focus on this message</span>
        </a>
      </div>
    </div>

    <message-result-body
      messageType="chat"
      :messageSource="message"
      :hasNotes="message._source.has_chat_message_notes"
      :featured="featured"
      :indexType="'chat'"
      @toggle-post="keepMessageInView"
    />
    <message-result-details
      v-if="message._source.has_media"
      :docId="message._id"
      :mediaSearchUrl="mediaSearchUrl"
      :messageSource="message"
      :indexType="'chat'"
    />
  </div>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'
import MessageResultBody from '../message_search/MessageResultBody'
import MessageResultDetails from '../message_search/MessageResultDetails'

export default {
  name: 'ChatContextItem',
  components: {
    NoteButtons,
    MessageResultBody,
    MessageResultDetails
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    messageSearchUrl: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    featured: {
      type: Boolean,
      required: true
    },
    timeframe: {
      type: String,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
    }
  },
  computed: {
    rootclass() {
      var classnames = ''
      if (this.featured) {
        classnames = 'message message--chat timeline__focus'
      } else {
        classnames = 'message message--chat message--chat-simple timeline__nearby'
      }

      return classnames
    },
    targeted_link() {
      if (this.timeframe) {
        return '?timeframe=' + this.timeframe
      } else {
        return ''
      }
    }
  },
  methods: {
    getUserLink(siteName, userName) {
      return new URL('/en/message-search/?filters=site_name:' + siteName + ',username:' + userName, document.baseURI).href
    },
    readableDate(timestamp) {
      let datetime = new Date(timestamp * 1000)
      // Include both date and time for chat messages
      return `${datetime.toLocaleDateString('en-GB', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })} ${datetime.toLocaleTimeString('en-GB')}`
    },
    keepMessageInView(isShowing) {
      let messageBounds = this.$el.getBoundingClientRect()

      if (isShowing && messageBounds.top < 0) {
        let y = (window.pageYOffset + messageBounds.top) - 10
        let x = 0

        // Keep the message in view on the page when collapsing it:
        window.scrollTo({
          top: y,
          left: x,
          behavior: 'smooth'
        })
      }
    },
    refresh() {
      this.$emit('refreshed')
    },
  }
}
</script>

<style scoped>

</style>
