<template>
  <div class="all-notes-container">
    <div class="all-notes-select">
      <button
        class="all-notes-btn"
        :class="{ 'selected': selected == 'all' }"
        @click="showAllNotes"
      >
        All Notes
      </button>
      <button
        class="all-notes-btn"
        :class="{ 'selected': selected == 'user-notes' }"
        @click="filterMyNotes"
      >
        My Notes
      </button>
      <button
        class="all-notes-btn"
        :class="{ 'selected': selected == 'public' }"
        @click="filterPublicNotes"
      >
        Public Notes
      </button>
    </div>
    <div class="all-notes-filter">
      <input
        v-model="searchInput"
        type="text"
        placeholder="Search"
        @keypress="searchNotes"
        @keyup.delete="searchNotes"
      >
      <button
        v-if="searchInput"
        class="all-notes-clear-search"
        @click="clearSearch"
      >
        Clear Search
      </button>
      <div class="all-notes-filters">
        <div class="filter-type">
          Filter by Type:
          <select
            v-model="filterType"
            :class="{ 'active': filterType }"
            @change="handleFilterChange"
          >
            <option
              v-for="noteType in noteTypes"
              :key="noteType"
            >
              {{ noteType }}
            </option>
          </select>
          <button
            v-if="filterType"
            class="all-notes-clear-filter"
            @click="clearTypeFilter"
          >
            &#x2716;
          </button>
        </div>
        <div
          v-if="selected !== 'user-notes'"
          class="filter-type"
        >
          Filter by Author:
          <select
            v-model="filterOrg"
            @change="handleFilterChange"
          >
            <option
              v-for="noteOrg in noteOrgs"
              :key="noteOrg"
            >
              {{ noteOrg }}
            </option>
          </select>
          <button
            v-if="filterOrg"
            class="all-notes-clear-filter"
            @click="clearOrgFilter"
          >
            &#x2716;
          </button>
        </div>
      </div>
    </div>
    <div class="all-notes-header">
      <div>
        <a
          href="#"
          :class="{'asc': orgSort == 'asc', 'desc': orgSort == 'desc'}"
          @click.prevent="sortOrg"
        >
          Author
        </a>
      </div>
      <div>
        <a
          href="#"
          :class="{'asc': locationSort == 'asc', 'desc': locationSort == 'desc'}"
          @click.prevent="sortLocation"
        >
          Note Location
        </a>
      </div>
      <div>Note</div>
      <div>
        <a
          href="#"
          :class="{'asc': dateSort == 'asc', 'desc': dateSort == 'desc'}"
          @click.prevent="sortDate"
        >
          Date Created
        </a>
      </div>
      <div>Date Updated</div>
      <div>Visibility</div>
      <div />
    </div>
    <div
      v-for="(note, index) in displayedNotes"
      :key="index"
      class="all-notes-item"
    >
      <div>{{ note.organization }}</div>
      <div>
        <a
          :href="noteLink(note)"
          @click="setSearchFilters($event, note.note_on, note.choice_name, note.site_name, note.choice_id)"
        >
          {{ note.note_on }}: {{ note.choice_name }}
        </a>
      </div>
      <div>
        {{ shortenNote(note.choice_note) }}
        <button
          v-if="note.choice_note.length >= 200"
          class="all-notes-show-all-btn"
          @click="notePopupText = note.choice_note"
        >
          Show All
        </button>
      </div>
      <div>{{ note.date_noted }}</div>
      <div>{{ note.date_updated }}</div>
      <div>
        <img
          v-if="note.choice_display_type == 'PR'"
          class="note-type-icon"
          src="/images/icons/private-note.svg"
          title="Private Note"
        >
        <img
          v-if="note.choice_display_type == 'PB'"
          class="note-type-icon"
          src="/images/icons/public-note.svg"
          title="Public Note"
        >
      </div>
      <div>
        <edit-note-popup
          v-if="note.current_logged_user === note.user_id_id"
          :note_id="note.id"
          :note="note.choice_note"
          :visibility="note.choice_display_type"
          :date_created="note.date_noted"
          :date_updated="note.date_updated"
          @edit-note="edit_note"
        />
        <button
          v-if="note.choice_display_type === 'PB' && note.user_id_id === note.current_logged_user"
          class="notes-delete-btn"
          title="Delete"
          @click="showDisableConfirmBtn(note.id, index)"
        >
          <img src="/images/icons/delete_black_48dp.svg">
        </button>
        <button
          v-else-if="note.current_logged_user === note.user_id_id"
          class="notes-delete-btn"
          title="Delete"
          @click="showDeleteConfirmBtn(note.id, index)"
        >
          <img src="/images/icons/delete_black_48dp.svg">
        </button>
        <button
          v-else
          class="notes-contact-btn"
          title="Contact Author"
          @click="setContactForm(note.user_id_id, note.login_user_email, note.user_name, note.choice_name, note.note_on, note.choice_note, note.choice_id, note.site_name, note.site_type, note.choice_display_type)"
        >
          <img src="/images/icons/mail.svg">
        </button>
      </div>
    </div>
    <div
      v-if="confirmDelete"
      class="notes-confirm-delete"
    >
      <p>Are you sure you want to permanently remove this note?</p>
      <p>
        <button
          class="notes-confirm-btn"
          @click="delete_note"
        >
          Confirm Delete
        </button>
        <button
          class="notes-cancel-btn"
          @click="confirmDelete = false"
        >
          Cancel
        </button>
      </p>
    </div>
    <div
      v-if="confirmDisable"
      class="notes-confirm-delete"
    >
      <p>Are you sure you want to permanently remove this note?</p>
      <p>This note is visible to all users.</p>
      <p>
        <button
          class="notes-confirm-btn"
          @click="disable_note"
        >
          Confirm Delete
        </button>
        <button
          class="notes-cancel-btn"
          @click="confirmDisable = false"
        >
          Cancel
        </button>
      </p>
    </div>
    <div v-if="showContactForm">
      <div class="all-notes-contact-background">
        <UserNoteContactForm
          :userId="userId"
          :userEmail="userEmail"
          :noteUserName=" noteUserName"
          :indexType="indexType"
          :noteSiteName="noteSiteName"
          :itemType="itemType"
          :noteItemContent="noteItemContent"
          :noteContent="noteContent"
          :siteLink="siteLink"
          :noteDisplayType="noteDisplayType"
          @closeContact="showContactForm = false"
        />
      </div>
    </div>
    <div
      v-if="notePopupText"
      class="all-notes-full-note-popup-container"
      @click.self="notePopupText = ''"
    >
      <div class="all-notes-full-note-popup">
        <button
          class="all-notes-full-note-popup-close"
          @click="notePopupText = ''"
        >
          &#x2716;
        </button>
        {{ notePopupText }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UserNoteContactForm from './UserNoteContactForm'
import EditNotePopup from './EditNotePopup'
import Cookies from 'js-cookie'

export default {
  name: 'AllNotes',
  components: { UserNoteContactForm, EditNotePopup },
  data() {
    return {
      allNotes: null,
      displayedNotes: null,
      selected: 'all',
      dateSort: 'desc',
      orgSort: null,
      locationSort: null,
      filterType: null,
      noteTypes: [],
      filterOrg: null,
      noteOrgs: [],
      sortDir: {'desc': 1, 'asc': -1},
      searchInput: '',
      siteIndexs: {
        'site': 'forum-sites',
        'forum': 'forum-sites',
        'chat site': 'chat-sites'
      },
      showContactForm: false,
      userId: null,
      userEmail: null,
      noteUserName: null,
      confirmDisable: false,
      disableId: null,
      disableIndex: null,
      confirmDelete: false,
      deleteId: null,
      deleteIndex: null,
      notePopupText: '',
      indexType: null,
      noteSiteName: null,
      itemType: null,
      noteItemContent: null,
      noteContent: null,
      siteLink: null,
      noteDisplayType: null,
    }
  },
  mounted() {
    axios.get('/en/allnotes').then(response => {
      if (response.status === 200) {
        this.allNotes = response.data.list_data
        this.displayedNotes = this.allNotes
        this.sortByDate()
        this.typeFilters()
        this.orgFilters()
      }
    })
  },
  methods: {
    edit_note(args) {
      if (args['newNote'] !== 'cancel') {
        var noteData = {
          note_id: args['noteId'],
          new_note: args['newNote'],
          new_visibility: args['newVisibility']
        }

        const csrftoken = Cookies.get('csrftoken')
        axios.post('/en/message-search/notes/change/', noteData, {'headers': {'X-CSRFToken': csrftoken}}).then(
          resp => {
            console.log('got a response! %o', resp)

            if (resp.status === 200 && resp.data.note_changed === 'Success') {
              for (const obj in this.displayedNotes) {
                if (this.displayedNotes[obj].id === args['noteId']) {
                  this.displayedNotes[obj].choice_note = args['newNote']
                  this.displayedNotes[obj].choice_display_type = args['newVisibility']
                  var dateEdited = new Date()
                  var noteEditDatetime = dateEdited.toLocaleString('en-GB').replace(',', '')
                  this.displayedNotes[obj].date_updated = noteEditDatetime
                }
              }
            }
          }
        ).catch(
          err => { console.log('something went wrong! %o', err) }
        )
      }
    },
    noteLink(note) {
      let forum = ''
      let noteLocation = note.note_on.toLowerCase()
      if (noteLocation === 'forum') forum = '/' + note.site_name
      if (noteLocation === 'topic') {
        return '/en/message-search/?filters=topic_id%3A' + note.choice_id + '%2Csite_name%3A' + note.site_name
      }
      if (noteLocation === 'chat user' || noteLocation === 'user') {
        return '/en/message-search/?filters=username%3A' + note.choice_name + '%2Csite_name%3A' + note.site_name
      } else if (noteLocation === 'post') {
        return '/en/message-search/?doc_id=' + note.choice_doc_id
      } else if (noteLocation === 'chat message') {
        return '/en/message-search/chat-sites/' + note.site_name + '/' + note.choice_doc_id
      } else if (noteLocation === 'media') {
        return '/en/media/?sha1=' + note.choice_id
      } else {
        return '/en/message-search/' + this.siteIndexs[noteLocation] + forum + '/' + note.choice_name
      }
    },
    setSearchFilters(e, type, name, siteName, choiceId) {
      if (type === 'Chat User') {
        e.preventDefault()
        localStorage.setItem('filters', JSON.stringify([{term: name, type: 'username'}]))
        window.location = e.target.href
      } else if (type === 'topic') {
        e.preventDefault()
        localStorage.setItem('filters', JSON.stringify([{term: siteName, type: 'site_name'}, {term: choiceId, type: 'topic_id'}]))
        window.location = e.target.href
      }
    },
    filterPublicNotes() {
      this.selected = 'public'
      if (this.searchInput.length > 0) {
        this.searchNotes()
      } else {
        this.displayedNotes = this.allNotes.filter(note => note.choice_display_type === 'PB')
        this.checkActiveFilters()
      }
    },
    filterMyNotes() {
      this.selected = 'user-notes'
      if (this.searchInput.length > 0) {
        this.searchNotes()
      } else {
        this.displayedNotes = this.allNotes.filter(note => note.current_logged_user === note.user_id_id)
        this.checkActiveFilters()
      }
    },
    showAllNotes() {
      this.selected = 'all'
      if (this.searchInput.length > 0) {
        this.searchNotes()
      } else {
        this.displayedNotes = this.allNotes
        this.checkActiveFilters()
      }
    },
    checkSelected() {
      if (this.selected === 'user-notes') this.displayedNotes = this.displayedNotes.filter(note => note.current_logged_user === note.user_id_id)
      if (this.selected === 'public') this.displayedNotes = this.displayedNotes.filter(note => note.choice_display_type === 'PB')
    },
    handleFilterChange() {
      this.displayedNotes = this.allNotes
      this.checkActiveFilters()
    },
    checkActiveFilters() {
      if (this.filterType) {
        this.filterByType()
      }
      if (this.filterOrg) {
        this.filterByOrg()
      }
    },
    sortOrg() {
      this.dateSort = null
      this.locationSort = null
      this.orgSort = (this.orgSort === 'desc') ? 'asc' : 'desc'
      this.sortByOrg()
    },
    sortByOrg() {
      this.displayedNotes.sort((a, b) => this.sortDir[this.orgSort] * (b.organization - a.organization))
    },
    sortLocation() {
      this.dateSort = null
      this.orgSort = null
      this.locationSort = (this.locationSort === 'desc') ? 'asc' : 'desc'
      this.sortByLocation()
    },
    sortByLocation() {
      this.displayedNotes.sort((a, b) => this.sortDir[this.locationSort] * a.choice_name.localeCompare(b.choice_name))
    },
    sortDate() {
      this.orgSort = null
      this.locationSort = null
      this.dateSort = (this.dateSort === 'desc') ? 'asc' : 'desc'
      this.sortByDate()
    },
    sortByDate() {
      this.displayedNotes.sort((a, b) => {
        let timeA = a.date_noted.split(' ')
        let datesA = timeA[0].split('/')
        let timeB = b.date_noted.split(' ')
        let datesB = timeB[0].split('/')
        const date1 = new Date(datesA[2] + '-' + datesA[1] + '-' + datesA[0] + 'T' + timeA[1])
        const date2 = new Date(datesB[2] + '-' + datesB[1] + '-' + datesB[0] + 'T' + timeB[1])
        if (this.dateSort === 'desc') return date2 - date1
        else if (this.dateSort === 'asc') return date1 - date2
      })
    },
    checkActiveSort() {
      if (this.dateSort) this.sortByDate()
      if (this.locationSort) this.sortByLocation()
      if (this.orgSort) this.sortByOrg()
    },
    orgFilters() {
      let orgsArray = []
      this.allNotes.forEach(note => {
        orgsArray.push(note.organization)
      })
      this.noteOrgs = orgsArray.filter((v, i, a) => a.indexOf(v) === i)
    },
    typeFilters() {
      let typesArray = []
      this.allNotes.forEach(note => {
        typesArray.push(note.note_on)
      })
      this.noteTypes = typesArray.filter((v, i, a) => a.indexOf(v) === i)
    },
    filterByType() {
      this.displayedNotes = this.displayedNotes.filter(note => note.note_on === this.filterType)
    },
    filterByOrg() {
      this.displayedNotes = this.displayedNotes.filter(note => note.organization === this.filterOrg)
    },
    clearTypeFilter() {
      this.filterType = null
      this.resetFilter()
    },
    clearOrgFilter() {
      this.filterOrg = null
      this.resetFilter()
    },
    resetFilter() {
      this.displayedNotes = this.allNotes
      if (this.selected === 'public') this.filterPublicNotes()
      else if (this.selected === 'user-notes') this.filterMyNotes()
      else if (this.selected === 'all') this.showAllNotes()
    },
    searchNotes() {
      let searchResult = []
      searchResult = this.allNotes.filter(note => {
        if (!note.choice_name) {
          return note.note_on.toUpperCase().includes(this.searchInput.toUpperCase()) ||
              note.choice_note.toUpperCase().includes(this.searchInput.toUpperCase())
        } else {
          return note.choice_name.toUpperCase().includes(this.searchInput.toUpperCase()) ||
              note.note_on.toUpperCase().includes(this.searchInput.toUpperCase()) ||
              note.choice_note.toUpperCase().includes(this.searchInput.toUpperCase())
        }
      })
      if (searchResult.length > 0) this.displayedNotes = searchResult
      this.checkSelected()
      this.checkActiveFilters()
      this.checkActiveSort()
    },
    clearSearch() {
      this.searchInput = ''
      this.displayedNotes = this.allNotes
      this.checkSelected()
      this.checkActiveFilters()
      this.checkActiveSort()
    },
    setContactForm(id, email, name, noteItemContent, itemType, noteContent, choiceId, siteName, siteType, displayType) {
      this.showContactForm = true
      this.userId = id
      this.userEmail = email
      this.noteUserName = name
      this.indexType = siteType
      this.noteSiteName = siteName
      this.itemType = itemType

      // link is different for each note type
      if (itemType.toLowerCase() === 'media') {
        this.noteItemContent = choiceId
        this.siteLink = new URL('/en/media/' + '?sha1=' + choiceId, document.baseURI).href
      } else if (itemType.toLowerCase() === 'chat message') {
        this.noteItemContent = new URL('/en/message-search/chat-sites/' + this.noteSiteName + '/' + choiceId + '/',
          document.baseURI).href
      } else if (itemType.toLowerCase() === 'post') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/?filters=site_name:' + siteName + ',post_id:' + choiceId,
          document.baseURI).href
      } else if (itemType.toLowerCase() === 'user' || itemType.toLowerCase() === 'chat user') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/?filters=site_name:' + siteName + ',username:' + noteItemContent,
          document.baseURI).href
      } else if (itemType.toLowerCase() === 'topic') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/?filters=site_name:' + siteName + ',topic:' + noteItemContent,
          document.baseURI).href
      } else if (itemType.toLowerCase() === 'site') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/forum-sites/' + siteName + '/', document.baseURI).href
      } else if (itemType.toLowerCase() === 'forum') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/forum-sites/' + siteName + '/' + noteItemContent + '/',
          document.baseURI).href
      } else if (itemType.toLowerCase() === 'chat site') {
        this.noteItemContent = noteItemContent // item_name
        this.siteLink = new URL('/en/message-search/chat-sites/' + siteName + '/', document.baseURI).href
      } else { // in case if there is anything left
        this.noteItemContent = noteItemContent // item_name
      }

      this.noteContent = noteContent
      this.noteDisplayType = displayType
    },
    showDisableConfirmBtn(id, index) {
      this.disableId = id
      this.disableIndex = index
      this.confirmDisable = true
    },
    showDeleteConfirmBtn(id, index) {
      this.deleteId = id
      this.deleteIndex = index
      this.confirmDelete = true
    },
    disable_note() {
      this.displayedNotes.splice(this.disableIndex, 1)
      let note = {
        'pk': this.disableId
      }
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/message-search/notes/disable/', note, {'headers': {'X-CSRFToken': csrftoken}})
      this.disableId = null
      this.disableIndex = null
      this.confirmDisable = false
    },
    delete_note() {
      this.displayedNotes.splice(this.deleteIndex, 1)
      let note = {
        'pk': this.deleteId
      }
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/message-search/notes/delete/', note, {'headers': {'X-CSRFToken': csrftoken}})
      this.deleteId = null
      this.deleteIndex = null
      this.confirmDelete = false
    },
    shortenNote(note) {
      if (note.length >= 200) {
        this.expandBtn = true
        return note.substring(0, 200) + '...'
      } else {
        this.expandBtn = false
        return note
      }
    },
  }
}
</script>

<style>
.all-notes-select {
  margin-bottom: 20px;
}
.all-notes-header,
.all-notes-item {
  display: flex;
}
.all-notes-header > div,
.all-notes-item > div {
  flex-basis: 50%;
  padding: 10px;
}
.all-notes-header > div:nth-child(n+1):nth-child(-n+2),
.all-notes-item > div:nth-child(n+1):nth-child(-n+2) {
  max-width: 200px;
}
.all-notes-header > div:nth-child(4),
.all-notes-item > div:nth-child(4) {
  max-width: 160px;
  text-align: center;
}
.all-notes-header > div:nth-child(5),
.all-notes-item > div:nth-child(5) {
  max-width: 160px;
  text-align: center;
}
.all-notes-header > div:nth-child(6),
.all-notes-item > div:nth-child(6) {
  max-width: 100px;
  text-align: center;
}
.all-notes-header > div:last-child,
.all-notes-item > div:last-child {
  max-width: 150px;
  text-align: center;
}
.all-notes-header {
  font-weight: bold;
  border-bottom: 2px solid #f5f5f4;
}
.all-notes-item:nth-child(even) {
  background: #f5f5f4;
}
.all-notes-btn {
  background: #d5d2d1;
  border-radius: 6px;
  padding: 5px;
  color: #474443;
  margin-right: 10px;
}
.all-notes-btn.selected {
  background: #474443;
  color: #fff;
}
.all-notes-filter {
  margin-bottom: 30px;
  position: relative;
}
.all-notes-filter input {
  width: 326px;
}
.all-notes-filter select {
  width: 200px;
}
.all-notes-filter select.active {
  background: none;
}
.all-notes-filters {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
}
.filter-type {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
.all-notes-clear-filter {
  color: #fff;
  background: #c22c3f;
  position:absolute;
  top:12px;
  right:10px;
  border-radius: 6px;
  padding: 2px;
  line-height: 18px;
}
.desc:after {
  content: '▼';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.asc:after {
  content: '▲';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.all-notes-clear-search {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 6px;
}
.all-notes-delete-btn,
.all-notes-contact-btn {
  border-radius: 6px;
  padding: 5px;
  background: #fff;
}
.all-notes-delete-btn {
  color: #c22c3f;
  border: 1px solid #c22c3f;
}
.all-notes-contact-btn {
  color: #000;
  border: 1px solid #000;
}
.all-notes-contact-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.2);
}
.all-notes-contact-background .note-contact-form {
  width: 50vw;
  top: 200px;
}
.all-notes-contact-background .note-contact-form p {
  margin-bottom: 1.25rem;
}
.all-notes-container .notes-confirm-delete {
  position: fixed;
}
.all-notes-container .notes-confirm-delete p {
  margin-bottom: 1.25rem;
}
.all-notes-full-note-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
}
.all-notes-full-note-popup {
  position: fixed;
  top: 200px;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 60vw;
  max-height: 60vh;
  background: #f5f5f4;
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
  white-space: pre-line;
  overflow: scroll;
}
.all-notes-full-note-popup-close {
  position: absolute;
  top: 5px;
  right: 10px;
}
.all-notes-show-all-btn {
  color: #c22c3f;
  float: right;
}
</style>
